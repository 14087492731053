<template>
    <div class="putInto">
        <div class="content-title ">出库类型</div>
        <van-cell title="类型选择"  :value="stateText" @click="showState=true"/>
        <van-popup v-model="showState" round position="bottom">
            <van-picker title="选择类型" show-toolbar
                        :columns="stateList"
                        @cancel="showState = false"
                        @confirm="changeStateName"
            />
        </van-popup>
            <div class="content-title ">出库原因</div>
            <van-field  class="workNote"
                        v-model="stationTxt"
                        rows="1"
                        autosize
                        type="textarea"
                        placeholder="请输入出库原因，不支持出库到站点（5-50字）"
                        maxlength="50"
            />
        <div class="content-title ">库存列表</div>
        <div v-for="(item,idx) in equlist" :key="idx">
            <van-field :label="item.itemName + '-' + item.unitPrice + '(元) *' + item.quantity"
                       label-width="60%"
                       v-model="item.num"
                       placeholder="请输入出库数量"
                       type="number"
                       :formatter="formatter"/>
        </div>
        <div class="imgUpload" >
            <div class="imgTitleDiv">
                <p class="lineTitle">请上传干系人和相应物品水印照片合影(非必传)</p>
            </div>
            <div class="imgDiv">
                <van-uploader v-model="imgArr" accept="image" :max-count="2"  :after-read="afterRead" :before-delete="deletePic"/>
            </div>

        </div>
        <div class="button-one">
            <van-button type="primary" color="#3F7C53" @click="affirm">确 定</van-button>
        </div>

    </div>

</template>

<script>
    import common from '@/utils/common'
    import {selectStockInfoForOtherPut,manualOutNoStation} from '@/utils/putInto';
    import {Toast} from "vant";
    import JsZip from "jszip";
    import {uploadZipAndAlreadyImg} from "../../utils/api";
    export default {
        name: "otherFacilityRemoval.vue",
        data(){
            return{
                imgArr:[],
                uploadZipImgList:[],
                stationTxt:'',
                stateText:'请选择',
                stateValue:'',
                showState:false,
                stateList:[
                    {
                        text:'请选择',
                        value:''
                    },
                    {
                        text:'好物内销',
                        value:'outStock_haowu'
                    },
                    {
                        text:'员工福利',
                        value:'outStock_employee_welfare'
                    },
                    {
                        text:'线上活动',
                        value:'outStock_online'
                    },
                    {
                        text:'招待礼品',
                        value:'outStock_entertain'
                    },
                    {
                        text:'会议物品',
                        value:'outStock_meeting'
                    },
                    {
                        text:'银行联合营销',
                        value:'outStock_bank'
                    },
                    {
                        text:'样品出库',
                        value:'outStock_sample'
                    },
                    {
                        text:'其他',
                        value:'outStock_other'
                    }
                ],
                equlist:[]

            }
        },
        created(){
            this.getEquipment();
        },
        methods:{
            formatter(value) {
                // 过滤输入的数字
                return value.replace(/\./, '');
            },
            getEquipment(){
                selectStockInfoForOtherPut({}).then(res=>{
                    if(res.code ===200 && res.data.businessCode === 200){
                        this.equlist= res.data.data;
                    }

                })
            },
            changeStateName(value){
                this.stateText = value.text;
                this.stateValue = value.value;
                this.showState = false;
            },
            affirm(){
                if(!this.stateValue||""==this.stateValue){
                    this.$message.warning('请选择出库类型');
                    return;
                }
                if(!this.stationTxt){
                    this.$message.warning('请输入出库原因');
                    return;
                }
                if(this.stationTxt && (this.stationTxt.length < 5 || this.stationTxt.length > 50)){
                    this.$toast('出库原因字段长度为5-50')
                    return
                }
                let arr = this.equlist;
                let putIntoArr = [];
                for(let i = 0; i < arr.length; i++){
                    if(arr[i].num){
                        if(arr[i].num==='0'){
                            Toast('数量必须大于0 !');
                            return;
                        }
                        putIntoArr.push(arr[i]);
                    }
                }
                if(putIntoArr.length === 0 ){
                    Toast('请输入数量!');
                    return;
                }


                if(this.uploadZipImgList.length < 1 ){
                    let data = {
                        outItemList:putIntoArr,
                        stationTxt:this.stationTxt,
                        stateValue:this.stateValue,
                    }
                    this.$dialog.alert({
                        message: '请确认对这些物品出库？',
                        showCancelButton: true,
                        confirmButtonColor:'#3F7C53'
                    }).then(() => {
                        manualOutNoStation(data).then(res=>{
                            console.log('确认按钮', res);
                            if(res.code ===200 && res.data.businessCode === 200){
                                this.$message.success('出库成功!');
                                this.$router.go(-1);
                                common.setItem('active', 4)
                            }
                        })
                    })
                    return;
                }

                let self = this

                var zip = new JsZip();
                var img = zip.folder("images");
                for (var i=0;i<this.uploadZipImgList.length;i++){
                    img.file("facilityPic_"+ i+this.uploadZipImgList[i].name, this.uploadZipImgList[i].data.substring(this.uploadZipImgList[i].data.indexOf(",")+1), {base64: true});
                }
                let json = {imgName: self.fileImgUrls}
                let fileAleradyJson = JSON.stringify(json)
                zip.generateAsync({type:"blob"}).then(function(content) {
                    console.log("上传图片到服务器")
                    uploadZipAndAlreadyImg(content,"1",fileAleradyJson).then(res=>{
                        console.log("res", res)
                        if (res.code == 200){
                            if (res.data.businessCode && res.data.businessCode === 200) {
                                //self.subData.imgPath =JSON.stringify(res.data.facilityPic)
                                let data = {
                                    outItemList:putIntoArr,
                                    stationTxt:self.stationTxt,
                                    stateValue:self.stateValue,
                                    imgPath:JSON.stringify(res.data.facilityPic)
                                }
                                self.$dialog.alert({
                                    message: '请确认对这些物品出库？',
                                    showCancelButton: true,
                                    confirmButtonColor:'#3F7C53'
                                }).then(() => {
                                    manualOutNoStation(data).then(res=>{
                                        console.log('确认按钮', res);
                                        if(res.code ===200 && res.data.businessCode === 200){
                                            self.$message.success('出库成功!');
                                            self.$router.go(-1);
                                            common.setItem('active', 4)
                                        }
                                    })
                                })
                                    .catch(() => {
                                    })
                            }
                        }
                    }).catch(()=>{
                    })

                },e=>{
                    this.$toast('上传图片异常')

                });



            },
            afterRead(file, detail) {
                // 大于15M
                if(file.file.size > 15 * 1024 * 1024){
                    Toast('图片太大，请上传小于15M的图片')
                    return
                }
                if(!common.imageVerify(file.file.type)){
                    this.fileList.splice(detail.index, 1)
                    Toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                let _this = this
                if(file.file.size < 100 * 1024){
                    console.log("小图片")
                    let namearr = file.file.name.split('.')
                    let name = '.'+namearr[namearr.length-1]
                    _this.uploadZipImgList.push({data:file.content,name:name})
                    return
                }
                let reader = new FileReader()
                reader.readAsDataURL(file.file)

                reader.onload = function (e) {
                    let content= file.content //图片的src，base64格式
                    let img = new Image()
                    let namearr = file.file.name.split('.')
                    let name = '.'+namearr[namearr.length-1]
                    img.src = content
                    img.onload = function() {
                        console.log("压缩图片")
                        common.compress(img, (dataURL)=>{
                            _this.uploadZipImgList.push({data:dataURL,name:name})
                        })
                    }
                }
            },
            deletePic(file, pic) {
                this.imgArr.splice(pic.index,1)
                this.uploadZipImgList.splice(pic.index,1)
            },
        }
    }
</script>

<style lang="less">
    @import "../../style/putInto.less";
    .search-box{
        .van-cell{
            padding: 14px 15px ;
            border-bottom: 1px solid #F7F8F9;
        }
    }
    .imgUpload{
        background: #ffffff;
        padding-bottom: 20px;
    }
    .imgTitleDiv{
        padding: 16px 15px 16px 15px ;
        background: #fff;
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    .imgDiv{
        padding: 0 15px;
    }
    .content-title{
        margin: 15px 0.3rem;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        height: 15px;
        line-height: 16px;
        border-left:2px solid #3F7C53;
        padding-left: 4px;

    }
    .workNote{
        border-top: 1px solid #EEEEEE;
    }

</style>
